import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { ArrowLeftIcon } from './icons'
import KulturaWSieci from './kultura-w-sieci.svg'
import KulturaWSieciContrast from './kultura-w-sieci-contrast.svg'
import { Actions, Theme } from './reducer'

const About = ({ state, dispatch }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })
  const isSmartphone = useMediaQuery({
    query: '(max-device-width: 586px)',
  })

  useEffect(() => {
    if (!state.highContrast) {
      dispatch({ type: Actions.themeSwitch, payload: Theme.LIGHT })
    }
  }, [state.highContrast, dispatch])

  return (
    <section
      className={`m-auto h-full flex flex-col pt-3 items-center ${
        isDesktopOrLaptop && 'pb-20'
      } ${!isDesktopOrLaptop && 'px-3'}`}
      style={{ maxWidth: '54rem' }}
    >
      <nav className="flex flex-row w-full items-center justify-evenly mb-6">
        <button
          onClick={() => history.goBack()}
          className="flex flex-row items-center flex-1 text-left hover:text-green-600"
        >
          {!isSmartphone && <ArrowLeftIcon />}
          <span className="ml-2">{t('Back')}</span>
        </button>
        <h1>{t('About the trail')}</h1>
        <span className="flex flex-1 justify-end opacity-0" role="img"></span>
      </nav>

      <main
        className={`flex flex-col mx-3 sm:mx-8 py-6 font-light text-justify overflow-y-auto ${
          state.fontSize && 'text-lg'
        }`}
        style={{ color: state.highContrast ? '#f6e05e' : '#2d4059' }}
      >
        <h2 className="text-lg mb-3 font-bold">Kim był Św. Jakub?</h2>
        <p className="font-light">
          Jakub Starszy był jednym z 12 apostołów, młodszym bratem Jana
          Ewangelisty, rybakiem mieszkającym nad jeziorem Galilejskim. Należał
          do pierwszych uczniów Jezusa. Podczas swojego życia św. Jakub odbył
          podróż do Hiszpanii, gdzie głosił Dobrą Nowinę, był także pierwszym
          biskupem Jerozolimy. Podczas pierwszego prześladowania chrześcijan
          Jakub Starszy został wydany przez żydowskiego arcykapłana królowi
          Herodowi Agryppie, który skazał go na ścięcie. Z jego życiem na ziemi,
          jak i po śmierci związanych jest wiele legend. Według jednej z nich
          św. Jakub pojawił się na białym koniu podczas bitwy pod hiszpańskim
          Clavijo w 844 roku i przepędził Maurów. Inna legenda mówi, że
          doprowadził do zmartwychwstania syna pielgrzymów powieszonego za
          kradzież. W Kościele Zachodu Jakub Starszy jest patronem pielgrzymów,
          zakonów rycerskich oraz patronem Hiszpanii i Portugalii. Na Wschodzie
          podlą się do niego rybacy prosząc o obfite połowy. Jest także
          przedstawiany jako patron aptekarzy, drogistów, robotników i żebraków.
        </p>
        <h2 className="text-lg mt-10 mb-3 font-bold">
          Najważniejszy szlak pielgrzymkowy w Europie
        </h2>
        <p className="font-light">
          Co roku setki tysięcy rowerzystów i piechurów z całego świata za cel
          swojej podróży obiera grób św. Jakuba w Santiago de Compostela. Szlak
          św. Jakuba jest jednym z najważniejszych szlaków kulturowych oraz
          chrześcijańskich dróg pielgrzymkowych w Europie. Szczątki Jakuba
          Apostoła zaginęły podczas przenosin z Jerozolimy, gdzie w 44 roku
          pochowano męczennika. Według legendy w IX wieku pewien pustelnik
          zobaczył deszcz gwiazd spadających na wzgórze cmentarne. Podążając za
          gwiazdami odnalazł marmurowy sarkofag. Okazało się, że w zawierał on
          poszukiwane od wieków szczątki świętego Jakuba. W miejscu, w którym
          odnaleziono sarkofag wkrótce powstała osada, którą nazwano Campus
          Stellae – „Pole Gwiazd”. Nad relikwiami wzniesiono kościół i niedługo
          potem do osady, obecnej Camposteli, przeniesiono siedzibę biskupią.
          Wtedy rozpoczęły się pierwsze pielgrzymki do grobu świętego. Już w XII
          wieku miejsce stało się trzecim, po Jerozolimie i Rzymie, ośrodkiem
          chrześcijaństwa. W średniowieczu w Santiago de Compostela rejestrowano
          nawet 500 tysięcy pielgrzymów rocznie. Ruch pielgrzymkowy do Santiago
          de Compostela odrodził się w czasach współczesnych za sprawą Jana
          Pawła II, który w 1982 roku odbył podróż do tego miejsca kultu, gdzie
          otwarcie nawoływał do „powrotu do korzeni” i promował szlak Jakuba
          Apostoła. W drugiej połowie lat 80. Rada Europy zatwierdziła Drogę św.
          Jakuba jako pierwszy Europejski Szlak Kulturowy. W 1993 roku szlak
          został wpisany na listę Światowego Dziedzictwa UNESCO. Wtedy także
          odwiedziła go rekordowa liczba pielgrzymów – tego roku prawie 10
          tysięcy osób przybyło do sanktuarium Santiago de Compostela.
        </p>
        <h2 className="text-lg mt-10 mb-3 font-bold">
          Pomorska Droga Św. Jakuba
        </h2>
        <p className="font-light">
          Kult św. Jakuba na ziemiach polskich rozwijał się dzięki działalności
          benedyktynów i dominikanów. Muszle przegrzebków – będących symbolem
          św. Jakuba, odnajdywane są podczas wykopalisk archeologicznych w
          różnych częściach Polski. W hiszpańskich archiwach również znajdziemy
          źródła potwierdzające popularność tego miejsca kultu wśród dawnych
          mieszkańców ziem polskich. Droga św. Jakuba to sieć szlaków, która
          pokrywa całą Europę. Pomorska Droga św. Jakuba jest stosunkowo młodym
          szlakiem. Została oddana do użytku w 2013 roku. Lokalne środowiska
          samorządowe, kościelne oraz branża turystyczna postanowiły połączyć
          siły i doprowadzić do rewitalizacji dawnego szlaku. Obecnie Droga
          wiedzie z Kretyngi na Litwie przez Obwód Kaliningradzki do Braniewa w
          Polsce. Na terenie naszego kraju przebiega, między innymi, przez
          Frombork, Elbląg, Gdańsk, Lębork, Słupsk, Koszalin, Kołobrzeg,
          Świnoujście i Szczecin. Na wyspie Uznam łączy się ze szlakiem
          niemieckim, skąd przez Francję biegnie do Hiszpanii. Jego końcowym
          przystankiem jest „Pole Gwiazd” – sanktuarium Santiago de Compostela.
          Przy wyznaczaniu szlaku eksperci brali pod uwagę zarówno jego
          średniowieczny przebieg, jak i współczesne miejsca kultu oraz
          pobliskie atrakcje turystyczne.
        </p>
        <div className="flex flex-row flex-wrap justify-around mt-10">
          <img
            alt="Dofinansowanie Ministerstwa Rozwoju i Technologii"
            src="/ftr_kolor.png"
            className="sm:w-1/4 mx-5 my-2.5"
          />
          <img
            alt="Kultura w sieci - logo"
            src={state.highContrast ? KulturaWSieciContrast : KulturaWSieci}
            className="sm:w-1/4 mx-5 flex-1"
          />
        </div>
        <h2 className="text-lg mt-10 mb-3 font-bold">
          Rozwój strony Camino360.pl
        </h2>
        <p className="font-light">
          W ramach projektu SPRAWNIE DLA NIEPEŁNOSPRAWNYCH – etap II
          przeprowadziliśmy szereg działań dostosowujących istniejący system
          informacji turystycznej o szlaku pn. Pomorska Droga św. Jakuba,
          Camino360.pl, do potrzeb turystów z niepełnosprawnościami oraz osób
          starszych:
        </p>
        <ul>
          <li>- możliwość zmiany wielkości i kroju fontu,</li>
          <li>- możliwość zmiany kontrastu strony,</li>
          <li>
            - ikonki i informacje na temat dostępności obiektów i udogodnień dla
            osób z niepełnosprawnościami,
          </li>
          <li>- możliwość odczytania tekstu przez lektora.</li>
        </ul>
        <p>
          Ponadto opracowaliśmy funkcję nawigacji do kolejnych punktów szlaku z
          możliwością automatycznej identyfikacji miejsca przebywania turysty
          korzystającego z aplikacji.
        </p>
        <div className="flex flex-row flex-wrap justify-around my-10">
          <img
            alt="Dofinansowanie Ministerstwa Rozwoju i Technologii"
            src="/ftr_kolor.png"
            className="sm:w-1/4 mx-5"
          />
          <img
            alt="Dofinansowanie Ministerstwa Rozwoju i Technologii"
            src="/mrit.png"
            className="sm:w-1/4 mx-5 flex-1"
          />
        </div>
      </main>
    </section>
  )
}

export default About
