import React, { useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import About from './About'
import { Controls } from './Controls'
import DesktopFooter from './Footer/DesktopFooter'
import Footer, { FOOTER_HEIGHT_REM } from './Footer/Footer'
import Guide from './Guide'
import Location from './Location/Location'
import PanoMap from './PanoMap/PanoMap'
import { initialState, reducer } from './reducer'
import { useCities, useGeolocation } from './Search/effects'
import Search from './Search/Search'
import { LocationFilters } from './utils'

const App = () => {
  // get the viewport height multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  const [locationFilter, setLocationFilter] = useState(LocationFilters.CITIES)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [location, setLocation] = useGeolocation(state.geoLocation)
  const { i18n } = useTranslation()
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)',
  })
  const cities = useCities(dispatch, i18n.language)

  return (
    <Router
      style={{
        position: 'relative',
      }}
    >
      <main
        style={{
          height: isMobileDevice
            ? `calc(${vh * 100}px - ${FOOTER_HEIGHT_REM}rem`
            : `calc(${vh * 100}px`,
          position: 'relative',
        }}
        className={`relative ${
          state.highContrast ? 'bg-black text-yellow-400' : 'inherit'
        }`}
      >
        <div className="absolute top-0 right-0 z-10">
          <Controls dispatch={dispatch} state={state} />
        </div>
        <Switch>
          {[
            LocationFilters.CITIES.routeBase,
            LocationFilters.HOTELS.routeBase,
            LocationFilters.ATTRACTIONS.routeBase,
          ].map((routeBase, idx) => (
            <Route
              key={idx + routeBase}
              path={`${routeBase}/:locationName`}
              children={<Location state={state} />}
            />
          ))}
          <Route
            path="/mapa/:locationName"
            children={
              <PanoMap
                cities={cities}
                dispatch={dispatch}
                locationFilter={locationFilter}
                setLocationFilter={setLocationFilter}
                state={state}
                userLocation={location}
              />
            }
          />
          <Route path="/mapa">
            <PanoMap
              cities={cities}
              dispatch={dispatch}
              locationFilter={locationFilter}
              setLocationFilter={setLocationFilter}
              state={state}
              userLocation={location}
            />
          </Route>
          <Route path="/szukaj">
            <Search
              state={state}
              dispatch={dispatch}
              cities={cities}
              locationFilter={locationFilter}
              setLocationFilter={setLocationFilter}
              setLocations={setLocation}
              userLocation={location}
            />
          </Route>
          <Route path="/przewodnik">
            <Guide state={state} dispatch={dispatch} />
          </Route>
          <Route path="/o-szlaku">
            <About state={state} dispatch={dispatch} />
          </Route>

          <Route path="/">
            <Location state={state} dispatch={dispatch} />
          </Route>
        </Switch>
      </main>
      {isMobileDevice ? (
        <Footer state={state} />
      ) : (
        <DesktopFooter state={state} />
      )}
    </Router>
  )
}

export default App
