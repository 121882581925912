import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Theme } from "../reducer";

export const DesktopNavItem = ({ active, to, state, children, i18nKey }) => {
  const { t } = useTranslation();

  return (
    <NavLink
      exact
      to={to}
      activeClassName={
        state.highContrast
          ? "bg-yellow-400"
          : state.theme === Theme.LIGHT
          ? "bg-gray-700 text-white"
          : "bg-white text-gray-700"
      }
      className={`${
        state.highContrast
          ? "hover:bg-yellow-400 border border-yellow-400 text-yellow-400 hover:text-gray-700"
          : state.theme === Theme.LIGHT
          ? "hover:text-white hover:bg-gray-700 bg-white"
          : "hover:text-gray-700 hover:bg-white text-white"
      } ${state.fontSize && "text-lg"} rounded-full px-8 py-3 flex flex-row`}
    >
      <div
        className={`${
          active &&
          (state.highContrast
            ? "text-black"
            : state.theme === Theme.DARK
            ? "text-gray-700"
            : "text-white")
        } flex items-center`}
      >
        {children}
        <span className={`font-bold`}>{t(i18nKey)}</span>
      </div>
    </NavLink>
  );
};
