import React, { useEffect, useRef } from 'react'
import { Viewer } from 'photo-sphere-viewer'
import { useTranslation } from 'react-i18next'
import { Actions } from '../reducer'

const Pano = ({ panoUrl, dispatch }) => {
  const { t } = useTranslation()
  const loadingTxt = t('Loading')

  let panoViewerRef = useRef(null)

  useEffect(() => {
    const panoViewer = new Viewer({
      container: panoViewerRef.current,
      panorama: panoUrl,
      loadingTxt,
      navbar: null,
      loadingImg: '/sw-jakub-logo-no-words.svg',
      // defaultLat: DEFAULT_LAT,
      autorotateDelay: 1,
      autorotateSpeed: '0.25rpm',
      mousewheel: false,
      mousemove: false,
      defaultZoomLvl: 40,
    })

    panoViewer.on('ready', async (_) =>
      dispatch({
        type: Actions.panoReadyToggle,
        panoReady: true,
      })
    )
    return () => panoViewer.destroy()
  }, [loadingTxt, panoViewerRef, panoUrl, dispatch])

  return <div ref={panoViewerRef} className={`w-full h-full`} />
}

export default Pano
