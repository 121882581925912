import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { ArrowLeftIcon, SearchIcon } from '../icons'
import { Actions, Theme } from '../reducer'
import { LocationFilters } from '../utils'
import { useDistanceSortedCities, useSearchFilter } from './effects'
import { List } from './List'

const Search = ({ cities, locationFilter, state, dispatch, userLocation }) => {
  const { t, i18n } = useTranslation()
  const [setDrawerVisible] = useState(false)
  const [searchPhrase, setSearchPhrase] = useState(null)

  const history = useHistory()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })
  const isSmartphone = useMediaQuery({
    query: '(max-device-width: 586px)',
  })

  useEffect(() => {
    if (!state.highContrast) {
      dispatch({ type: Actions.themeSwitch, payload: Theme.LIGHT })
    }
  }, [state.highContrast, dispatch])

  const [elements, setElements] = useDistanceSortedCities({
    userLocation,
    cities,
  })

  useSearchFilter({
    cities,
    searchPhrase,
    lang: i18n.language,
    setElements,
  })

  const onSearchPhraseChange = (event) => {
    setSearchPhrase(event.target.value)
  }

  return (
    <div
      className={`m-auto h-full flex flex-col pt-3 ${
        isDesktopOrLaptop && 'pb-20'
      } ${!isDesktopOrLaptop && 'px-3'}`}
      style={{ maxWidth: '54rem' }}
    >
      <nav className="flex flex-row w-full items-center justify-between">
        <button
          onClick={() => history.goBack()}
          className="flex flex-row items-center flex-1 text-left hover:text-green-600"
        >
          {!isSmartphone && <ArrowLeftIcon />}
          <span className="ml-2">{t('Back')}</span>
        </button>
        <h1 className="flex-1 justify-center">{t('Search')}</h1>
        <button
          className="flex flex-1 justify-end hover:text-green-600 items-center outline-none"
          role="img"
          onClick={() => setDrawerVisible(true)}
        ></button>
      </nav>
      <main className="overflow-y-auto w-full items-center flex flex-col mt-4">
        <div className="w-full flex flex-row items-center relative">
          <input
            onChange={onSearchPhraseChange}
            placeholder={
              locationFilter.id === LocationFilters.CITIES.id
                ? t('Enter the phrase.City Only')
                : t('Enter the phrase.Location')
            }
            className={`${
              state.highContrast
                ? 'text-yellow-400 bg-transparent border-yellow-400 placeholder-yellow-400'
                : 'bg-gray-100 border-gray-300'
            } rounded-full w-full p-3 border text-sm mb-3 outline-none ${
              state.fontSize && 'text-lg'
            }`}
          />
          <SearchIcon
            className={`mr-4 ${
              state.highContrast ? 'text-yellow-400' : 'text-gray-400'
            } absolute right-0 mb-3`}
          />
        </div>

        {elements.length > 0 && (
          <h3
            className={`${
              state.highContrast ? 'text-yellow-400' : 'text-gray-500'
            } my-1 self-center text-center ${state.fontSize && 'text-base'}`}
          >
            {t('Found')}: {elements.length}
            {state.geoLocation && (
              <>
                <br />
                <span>{t('Sorted by distance')}</span>
              </>
            )}
          </h3>
        )}

        {elements.length > 0 ? (
          <div className="w-full">
            <List
              elements={elements}
              state={state}
              searchPhrase={searchPhrase}
              userLocation={state.geoLocation && userLocation}
            />
          </div>
        ) : (
          <div
            className={`flex flex-1 flex-col ${
              state.highContrast ? 'text-yellow-400' : 'text-gray-500'
            } mt-10`}
          >
            <span>
              {t('No city found')}&nbsp;
              <span role="img" aria-label="sad-face">
                😢
              </span>
            </span>
            <span>{t('Change search phrase')}</span>
          </div>
        )}
      </main>
    </div>
  )
}

export default Search
