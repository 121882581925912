import React from 'react'
import { HeliIcon, RouteIcon } from '../icons'
import { getUrlToGoogleMaps } from '../utils'

const formatLabel = (label, value) =>
  !value ? (
    label
  ) : (
    <span>
      {label.split(new RegExp(value, 'gi')).reduce((aggregated, current, i) => {
        const getIndex = (label, current) =>
          label.toLowerCase().indexOf(current.toLowerCase())
        return !i
          ? [current]
          : aggregated.concat(
              <b className="text-green-600" key={value + current}>
                {label.substr(getIndex(label, value), value.length)}
              </b>,
              current
            )
      }, [])}
    </span>
  )

export const List = ({ elements, state, searchPhrase, userLocation }) => {
  return elements.map((location, index) => (
    <div
      key={index}
      className={`flex flex-wrap flex-row pb-3 ${
        state.highContrast
          ? 'bg-transparent text-yellow-400 border-yellow-400 border'
          : 'bg-gray-100 text-gray-700'
      }  bg-opacity-50 my-3 rounded-lg`}
    >
      <div className="flex-1 flex flex-col px-3">
        <div className="flex flex-row justify-between pt-3">
          <a
            href={location.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`underline text-lg mr-4 flex-1 ${
              state.fontSize && 'text-xl'
            }`}
          >
            {formatLabel(location.name, searchPhrase)}
          </a>
          <div className="flex flex-row">
            {userLocation && location.dist && (
              <span
                target="_blank"
                className={`text-lg text-green-600 ${
                  state.fontSize && 'text-xl'
                }`}
              >
                {location.dist}km
              </span>
            )}
            {userLocation && (
              <a
                className={`text-lg text-green-600 ml-4 ${
                  state.fontSize && 'text-xl'
                }`}
                target="_blank"
                rel="noopener noreferrer"
                href={getUrlToGoogleMaps({
                  userLocation,
                  target: location.coords,
                })}
              >
                <RouteIcon />
              </a>
            )}
            {location.flyview && <HeliIcon className="ml-4" />}
          </div>
        </div>
      </div>
    </div>
  ))
}
