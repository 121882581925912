import { useEffect, useState } from 'react'
import { Actions } from '../reducer'
import { LocationFilters } from '../utils'
import { calcCoordinatesToDistance } from './CalculateDistance'

export const useCities = (dispatch, lang) => {
  const [cities, setCities] = useState([])

  useEffect(() => {
    const path = '/data/cities.json'
    fetch(path)
      .then((response) => response.json())
      .then((cities) => {
        return cities
          .map((city) => {
            return {
              ...city,
              routeBase: LocationFilters.CITIES.routeBase,
            }
          })
          .sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
      })
      .then((cities) => {
        if (cities?.length) {
          setCities(cities)
          dispatch({ type: Actions.citiesFetched, payload: cities })
        }
      })
  }, [dispatch, lang])

  return cities
}

export const useSearchFilter = ({
  cities,
  searchPhrase,
  lang,
  setElements,
}) => {
  return useEffect(() => {
    setElements(
      searchPhrase
        ? cities.filter((city) => {
            const test = (name, phrase) =>
              name.toLowerCase().includes(phrase.toLowerCase())
            return test(city.name, searchPhrase)
          })
        : cities
    )
  }, [cities, searchPhrase, lang, setElements])
}

export const useGeolocation = (trigger) => {
  const [location, setLocation] = useState({
    lat: undefined,
    lng: undefined,
  })

  useEffect(() => {
    if (trigger) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      })
    }
  }, [trigger])

  return [location, setLocation]
}

export const useDistanceSortedCities = ({ userLocation, cities }) => {
  const [elements, setElements] = useState([])
  useEffect(() => {
    if (cities) {
      setElements(
        cities
          .map((city) => {
            return {
              ...city,
              dist:
                userLocation &&
                calcCoordinatesToDistance(
                  userLocation,
                  city.coords.lat,
                  city.coords.lng
                ),
            }
          })
          .sort((a, b) => (userLocation ? a.dist - b.dist : true))
      )
    }
  }, [userLocation, cities])

  return [elements, setElements]
}
