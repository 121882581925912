export const Theme = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
}

export const initialState = {
  theme: Theme.DARK,
  highContrast: false,
  fontSize: false,
  panoReady: false,
  cities: [],
  geoLocation: undefined,
}

export const Actions = {
  themeSwitch: 'themeSwitch',
  highContrastToggle: 'highContrastToggle',
  biggerFontsToggle: 'biggerFontsToggle',
  citiesFetched: 'citiesFetched',
  panoReadyToggle: 'panoReady',
  updateGeolocation: 'updateGeolocation',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.themeSwitch:
      return {
        ...state,
        theme: action.payload,
      }
    case Actions.highContrastToggle:
      return {
        ...state,
        highContrast: !state.highContrast,
      }
    case Actions.biggerFontsToggle:
      return {
        ...state,
        fontSize: !state.fontSize,
      }
    case Actions.citiesFetched:
      return {
        ...state,
        citiesFetched: action.payload,
      }
    case Actions.panoReadyToggle:
      return {
        ...state,
        panoReady: action.panoReady,
      }
    case Actions.updateGeolocation:
      return {
        ...state,
        geoLocation: action.geoLocation,
      }
    default:
      throw new Error()
  }
}
