import { motion } from 'framer-motion'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Link, useHistory } from 'react-router-dom'
import SwJakubLogo from '../logo.svg'
import { simulateButtonPress } from '../utils'

const Intro = ({ state }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const isSmartphone = useMediaQuery({
    query: '(max-device-width: 586px)',
  })
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })
  const redirectToMap = useCallback(() => {
    history.push('/mapa')
  }, [history])
  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      className={`absolute top-0 h-full w-full flex flex-col justify-center items-center bg-opacity-50 bg-black ${
        isDesktopOrLaptop && 'pb-20'
      }`}
    >
      <div
        style={{ height: isSmartphone ? '95%' : '40rem' }}
        className="flex flex-col items-center mx-6 lg:mx-64 justify-between"
      >
        <motion.div
          className="relative flex justify-center"
          style={{ width: isSmartphone ? '9rem' : '11rem' }}
          animate={{ opacity: [0, 1], top: [2, 0] }}
        >
          <img
            alt="Camino360 - logo"
            src={SwJakubLogo}
            className="w-4/5 sm:w-full"
          />
        </motion.div>
        <motion.div
          animate={{ opacity: [0, 0, 1], top: [2, 2, 0] }}
          className=" flex flex-col"
        >
          <h1
            className={`justify-center text-center text-xl sm:text-3xl text-amber-400 ${
              state.highContrast ? 'text-yellow-400 ' : 'text-white '
            }`}
          >
            {t('Visit region')}
          </h1>
        </motion.div>
        <motion.div
          className="flex flex-col w-11/12 xs:w-2/3 md:w-3/4 max-w-lg mt-2"
          animate={{
            opacity: [0, 0, 0, 1],
            top: [2, 2, 2, 0],
          }}
        >
          <button
            onClick={redirectToMap}
            onMouseOver={
              !state.highContrast ? (e) => simulateButtonPress(e) : null
            }
            onMouseLeave={
              !state.highContrast ? (e) => simulateButtonPress(e, false) : null
            }
            className={`flex flex-1 justify-center
           items-center rounded-full text-sm xs:text-base  ${
             state.highContrast
               ? 'text-gray-700 mt-4 bg-yellow-400 py-3 font-bold outline-none '
               : 'text-white bg-green-600 font-bold relative btn-thick outline-none'
           }`}
          >
            {t('Map').toUpperCase()}
          </button>
          <div className="flex flex-row flex-wrap justify-between">
            <Link
              to="/o-szlaku"
              className={`text-center flex flex-grow w-2/5 mr-0 md:mr-2 justify-center
              items-center rounded-full text-sm xs:text-base ${
                state.highContrast
                  ? 'text-yellow-400 mt-4 hover:bg-yellow-400 hover:text-gray-700 py-3 border border-yellow-400   font-bold outline-none '
                  : ' text-white border mt-4 hover:bg-white hover:text-gray-700 py-3 border-white font-bold outline-none'
              }`}
            >
              <span>{t('About the trail').toUpperCase()}</span>
            </Link>

            <Link
              to="/przewodnik"
              className={`flex flex-grow justify-center
              items-center rounded-full text-sm xs:text-base
              ${
                state.highContrast
                  ? 'text-yellow-400 mt-4 hover:bg-yellow-400 hover:text-gray-700 py-3 border border-yellow-400   font-bold outline-none '
                  : 'text-gray-700 border mt-4 hover:bg-white hover:text-gray-700 py-3 bg-white bg-opacity-75 font-bold outline-none"'
              }`}
            >
              <span>{t('Guided Tour').toUpperCase()}</span>
            </Link>
          </div>
          <div className="flex flex-row justify-center">
            <img
              alt="Dofinansowanie Ministerstwa Rozwoju i Technologii"
              src="/ftr_mono.png"
              className="w-48 sm:w-48 mt-10"
            />
          </div>
          <div className="flex flex-row justify-center">
            <img
              alt="Dofinansowanie Ministerstwa Rozwoju i Technologii"
              src="/mrit.png"
              className="w-4/5 sm:w-3/4 mt-6"
            />
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}
export default Intro
