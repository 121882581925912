import { AvailableLangs } from './i18n'

export const simulateButtonPress = (e, pressed = true, contrast = false) => {
  e.target.style.boxShadow = `0px ${pressed ? '2px' : '5px'} 0px 0px ${
    contrast ? '519400' : '#519400'
  }`
  e.target.style.top = pressed ? '3px' : 0
}

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1

export const LocationFilters = {
  CITIES: { id: 'CITIES', i18n_key: 'Cities', routeBase: '/miasta' },
  HOTELS: { id: 'HOTELS', i18n_key: 'Hotels', routeBase: '/hotele' },
  ATTRACTIONS: {
    id: 'ATTRACTIONS',
    i18n_key: 'Attractions',
    routeBase: '/atrakcje',
  },
}

export const getRandomLocation = (cities, cityIndexToSkip) => {
  let randomIndex = cityIndexToSkip
  while (randomIndex === cityIndexToSkip) {
    randomIndex = Math.round(Math.random() * (cities.length - 1))
  }
  return cities[randomIndex]
}

export const getUrlToGoogleMaps = ({ userLocation, target }) => {
  return `https://www.google.com/maps/dir/?api=1&orgin=${userLocation.lat},${userLocation.lng}&destination=${target.lat},${target.lng}`
}

const getLang = (langString) => {
  const result = AvailableLangs[0]
  if (langString) {
    const langShortcode = langString.substring(0, 2).toLowerCase()
    return AvailableLangs.find((lang) => langShortcode === lang.shortcode)
  }
  return result
}

export const getLangShortcode = (langString) => {
  const lang = getLang(langString)
  return lang && lang.shortcode ? lang.shortcode : undefined
}

export const getUrlWithLang = (url, langString) => {
  return `${url}&tour_language=${getLangShortcode(langString)}`
}
