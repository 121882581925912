import React from "react";
import { useLocation } from "react-router-dom";
import { HomeIcon, InfoIcon, MapIcon, PinIcon, SearchIcon } from "../icons";
import { NavItem } from "./NavItem";

export const FOOTER_HEIGHT_REM = '3.4'

const Footer = ({ state }) => {
  let match = useLocation();

  return (
    <div className="flex flex-col">
      <div
        className="w-full"
        style={{
          position: 'relative',
          height: `${FOOTER_HEIGHT_REM / 2}rem`,
          top: `-${FOOTER_HEIGHT_REM / 2}rem`,
        }}
      />
      <nav
        style={styles}
        className={`flex flex-row justify-around items-center lg:px-64 font-sans ${
          state.highContrast ? "bg-black" : "bg-gray-100"
        }`}
      >
        <NavItem
          active={match.pathname === "/"}
          to="/"
          state={state}
          i18nKey="Home"
        >
          <HomeIcon />
        </NavItem>
        <NavItem
          active={match.pathname === "/mapa"}
          to="/mapa"
          state={state}
          i18nKey="Map"
        >
          <PinIcon />
        </NavItem>
        <NavItem
          active={match.pathname === "/szukaj"}
          to="/szukaj"
          state={state}
          i18nKey="Search"
        >
          <SearchIcon />
        </NavItem>
        <NavItem
          active={match.pathname === "/przewodnik"}
          to="/przewodnik"
          state={state}
          i18nKey="Guide"
        >
          <MapIcon />
        </NavItem>
        <NavItem
          active={match.pathname === "/o-szlaku"}
          to="/o-szlaku"
          state={state}
          i18nKey="About the trail"
        >
          <InfoIcon />
        </NavItem>
      </nav>
    </div>
  )
}

const styles = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: `${FOOTER_HEIGHT_REM}rem`,
}

export default Footer
