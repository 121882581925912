import React from "react";
import { useLocation } from "react-router-dom";
import { HomeIcon, InfoIcon, MapIcon, PinIcon, SearchIcon } from "../icons";
import { DesktopNavItem } from "./DesktopNavItem";

export const FOOTER_HEIGHT_REM = '5'

const DesktopFooter = ({ state }) => {
  let match = useLocation();

  return (
    <nav
      style={styles}
      className={`flex justify-center \
      absolute bottom-0`}
    >
      <div className="flex font-bold my-4 justify-between flex-row w-full max-w-5xl">
        <DesktopNavItem
          active={match.pathname === "/"}
          to="/"
          state={state}
          i18nKey="Home"
        >
          <HomeIcon className="mr-6" />
        </DesktopNavItem>
        <DesktopNavItem
          active={match.pathname === "/mapa"}
          to="/mapa"
          state={state}
          i18nKey="Map"
        >
          <PinIcon className="mr-6" />
        </DesktopNavItem>
        <DesktopNavItem
          active={match.pathname === "/szukaj"}
          to="/szukaj"
          state={state}
          i18nKey="Search"
        >
          <SearchIcon className="mr-6" />
        </DesktopNavItem>
        <DesktopNavItem
          active={match.pathname === "/przewodnik"}
          to="/przewodnik"
          state={state}
          i18nKey="Guide"
        >
          <MapIcon className="mr-6" />
        </DesktopNavItem>
        <DesktopNavItem
          active={match.pathname === "/o-szlaku"}
          to="/o-szlaku"
          state={state}
          i18nKey="About the trail"
        >
          <InfoIcon className="mr-6" />
        </DesktopNavItem>
      </div>
    </nav>
  )
}

const styles = {
  height: `${FOOTER_HEIGHT_REM}rem`,
  right: '4rem',
  left: '4rem',
}

export default DesktopFooter
