import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { ArrowLeftIcon } from './icons'
import { Actions, Theme } from './reducer'

const Guide = ({ state, dispatch }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })
  const isSmartphone = useMediaQuery({
    query: '(max-device-width: 586px)',
  })

  useEffect(() => {
    if (!state.highContrast) {
      dispatch({ type: Actions.themeSwitch, payload: Theme.LIGHT })
    }
  }, [state.highContrast, dispatch])

  return (
    <section
      className={`m-auto h-full flex flex-col pt-3 items-center ${
        isDesktopOrLaptop && 'pb-20'
      } ${!isDesktopOrLaptop && 'px-3'}`}
      style={{ maxWidth: '54rem' }}
    >
      <nav className="flex flex-row w-full items-center justify-evenly mb-6">
        <button
          onClick={() => history.goBack()}
          className="flex flex-row items-center flex-1 text-left hover:text-green-600"
        >
          {!isSmartphone && <ArrowLeftIcon />}
          <span className="ml-2">{t('Back')}</span>
        </button>
        <h1>{t('Guide')}</h1>
        <span className="flex flex-1 justify-end opacity-0" role="img"></span>
      </nav>
      <main
        className={`items-center px-3 sm:px-8 py-5 font-thin overflow-y-auto ${
          state.fontSize && 'text-lg'
        }`}
        style={{ color: state.highContrast ? '#f6e05e' : '#2d4059' }}
      >
        <p className="mb-5 text-justify">
          Poniżej prezentujemy 3 odcinki wirtualnej wycieczki szlakiem Św.
          Jakuba. W podróż zabrał nas doświadczony przewodnik Tomasz Duda.
          Zapraszamy do obejrzenia!:
        </p>
        <iframe
          width="100%"
          height="315"
          title="Camino360° | wycieczka z przewodnikiem | etap I"
          src="https://www.youtube.com/embed/vFwRCxgC_BU"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          width="100%"
          height="315"
          className="mt-2"
          title="Camino360° | wycieczka z przewodnikiem | etap II"
          src="https://www.youtube.com/embed/uivYD6UJaDs"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          width="100%"
          height="315"
          className="mt-2"
          title="Camino360° | wycieczka z przewodnikiem | etap III"
          src="https://www.youtube.com/embed/w0VqfNQROoU"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </main>
    </section>
  )
}

export default Guide
