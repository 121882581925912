import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

// TODO change to dict
export const AvailableLangs = [
  { shortcode: 'pl', name: 'Polski' },
  { shortcode: 'de', name: 'Deutsch' },
]

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    react: {
      useSuspense: false,
    },
    lng: AvailableLangs[0].shortcode,
    fallbackLng: AvailableLangs[0].shortcode,
    interpolation: {
      escapeValue: false,
    },
  })
