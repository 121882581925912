import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import Pano from '../Pano/Pano'
import { Actions, Theme } from '../reducer'
import { getRandomLocation, isSafari } from '../utils'
import Intro from './Intro'

export const PanoKeys = [
  'bialogard',
  'budzistowo',
  'frombork',
  'iwiecino',
  'kartuzy1',
  'kartuzy2',
  'leba',
  'polanow',
  'sienowo',
  'zukowo',
  // in case you need some pano locally, uncomment this line and delete above. Do not commit it!
  //'sample',
]

const Location = ({ state, dispatch }) => {
  const [location] = useState(getRandomLocation(PanoKeys))
  useEffect(() => {
    if (!state.highContrast) {
      dispatch({ type: Actions.themeSwitch, payload: Theme.DARK })
    }
  }, [state.highContrast, dispatch])
  return (
    location && (
      <div className="h-full min-h-full bg-black">
        {!state.highContrast && (
          <Pano
            panoUrl={`/panos/${location}.${isSafari ? 'jpg' : 'webp'}`}
            dispatch={dispatch}
          />
        )}
        <AnimatePresence>
          {state.panoReady ? <Intro state={state} /> : null}
        </AnimatePresence>
      </div>
    )
  )
}

export default Location
