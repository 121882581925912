import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { CloseIcon } from '../icons'
import { simulateButtonPress } from '../utils'

const LocationPopup = ({
  state,
  location,
  popupVisible,
  setPopupVisible,
  close,
}) => {
  const { t } = useTranslation()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })

  useEffect(() => {
    setPopupVisible(!!location)
  }, [location, setPopupVisible])

  return (
    popupVisible && (
      <div
        className={`absolute bottom-0 px-6 pt-3 pb-5 rounded-lg ${
          state.highContrast ? 'bg-black' : 'bg-gray-100'
        }  shadow-lg ${isDesktopOrLaptop ? 'mb-24' : 'mb-6'}`}
      >
        <button
          className="outline-none absolute top-0 right-0 mr-2 mt-2"
          onClick={close}
        >
          <CloseIcon
            className={`small ${
              state.highContrast ? 'text-yellow-400' : 'text-gray-500'
            }`}
          />
        </button>
        <div className="flex flex-col items-center">
          <h2 className={`text-center ${state.fontSize && 'text-2xl'}`}>
            {location.name}
          </h2>
          <div className="flex flex-col">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={location.url}
              onMouseOver={(e) => simulateButtonPress(e, true, state)}
              onMouseLeave={(e) => simulateButtonPress(e, false, state)}
              className={`rounded-full ${
                state.highContrast
                  ? ' btn-thick-contrast text-black bg-yellow-400'
                  : 'btn-thick text-white bg-green-600'
              } relative py-3 px-4 text-sm xs:text-base xs:px-6 sm:px-8  my-2 flex-1 flex flex-row items-center`}
            >
              <span className={`${state.fontSize && 'text-lg'}`}>
                {t('3d walk').toUpperCase()}
              </span>
            </a>
          </div>
        </div>
      </div>
    )
  )
}

export default LocationPopup
