import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import App from './App'
import './assets/main.css'
import './assets/misc.css'
import './i18n'

ReactGA.initialize('G-2R0MNS0KHE')
ReactGA.pageview(window.location.pathname + window.location.search)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
