import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Theme } from "../reducer";

export const NavItem = ({ active, to, state, children, i18nKey }) => {
  const { t } = useTranslation();

  return (
    <NavLink
      exact
      to={to}
      className={`${
        state.highContrast
          ? "hover:text-black text-yellow-400 hover:bg-yellow-400 p-2 rounded-lg"
          : "hover:text-green-600 text-black"
      } flex flex-col items-center`}
      activeClassName={
        state.highContrast ? `text-black bg-yellow-400` : `text-green-600`
      }
    >
      <div
        className={`${
          active &&
          (state.highContrast
            ? "text-black"
            : state.theme === Theme.DARK
            ? "text-gray-700"
            : "white")
        } flex flex-col items-center`}
      >
        {children}
        <span className="text-xs">{t(i18nKey)}</span>
      </div>
    </NavLink>
  );
};
